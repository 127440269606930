import React, { useEffect, useRef, useContext } from "react"
import { AppContext } from "../../context/AppContext"
import Image from "../Images/index"
import styled from "@emotion/styled"
const HeroWrap = styled.div`
  background-color: white;
  max-height: 550px;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  overflow: hidden;

  @media all and (max-width: 1300px) {
    max-height: 550px;
  }
  @media all and (max-width: 768px) {
    max-height: 350px;
  }
  @media all and (max-width: 550px) {
    max-height: 220px;
  }
`
const HeroNoBg = styled.div`
  width: 100%;
  max-height: 550px;
  height: auto;
`
const HeroImage = styled.div`
  background-image: url("${props => props.dsrc}");
  width: 100%;
  height: 550px;
  background-repeat: no-repeat;
  background-size: 100%;

  @media all and (max-width: 1300px) {
    background-size:  1300px auto;
    height: 550px;
  }
  @media all and (max-width: 768px) {
    height: 350px;
    background-position: center ;
  }
  @media all and (max-width: 550px) {
    background-size: 130% auto;
  }
  /* background-color: red; */
`
const useScrollHandler = handler => {
  useEffect(() => {
    window.addEventListener("scroll", handler)
    return () => {
      window.removeEventListener("scroll", handler)
    }
  }, [handler])
}

//https://doc-0g-bc-docs.googleusercontent.com/docs/securesc/nfjorei8vjk6bh0o0v53mpoqrv6mhq9p/dkfnodsdpe1scqjjh4u3gt51gp9mukd3/1608698250000/07904197954487934895/05076325971004936750Z/1KV2RliBGonVoBldQpNytAYWogsGUhRY5?nonce=vm739lk9vcbse&user=05076325971004936750Z&hash=l4kejnbd03f8ipkbriv0p6ir4lau08j1
//https://drive.google.com/uc?id=1KV2RliBGonVoBldQpNytAYWogsGUhRY5
const Hero = ({ src }) => {
  const { setLoading } = useContext(AppContext)

  const ref = useRef()
  const handler = () => {
    ref.current.style.backgroundPosition = `center ${window.scrollY / 2}px`
  }

  useScrollHandler(handler)

  return (
    <HeroWrap>
      <HeroImage ref={ref} dsrc={src}></HeroImage>
      <img
        style={{ visibility: "hidden" }}
        src={src}
        alt="loading screen, Brazee Logo"
        onLoad={() => setLoading({ loading: false })}
      />
    </HeroWrap>
  )
}

export const FluidHero = ({ name }) => {
  const { setLoading } = useContext(AppContext)

  const ref = useRef()
  const handler = () => {
    ref.current.style.transform = `translateY( ${window.scrollY / 2}px)`
  }

  useScrollHandler(handler)
  return (
    <HeroWrap>
      <HeroNoBg ref={ref}>
        <Image fileName={name} alt="page header" objectFit="contain" />
      </HeroNoBg>
    </HeroWrap>
  )
}

export default Hero
