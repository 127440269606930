import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

const ContactUsForm = styled.div`
  &&.submitting {
    filter: blur(1);
    opacity: 0.2;
  }
  margin-top: 2rem;
  && input {
    padding: 1rem;
    height: 40px;
    display: block;
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 1rem;
    min-width: 40vw;
  }
  && label {
    margin-bottom: 1rem;
  }
  && textarea {
    border-radius: 5px;
    min-width: 40vw;
    height: 300px;
    padding: 1rem;
    display: block;
  }
  @media all and (max-width: 768px) {
    && input,
    && textarea {
      min-width: 100%;
    }
  }
`

const ContactForm = props => {
  const init = {
    name: "",
    email: "",
    subject: "",
    message: "",
  }
  const [formStatus, setFormStatus] = useState({
    submitting: false,
    submitted: false,
    valid: false,
    inputs: init,
  })

  const checkValid = () => {
    for (let v in formStatus.inputs) {
      if (formStatus.inputs[v] === "") {
        return setFormStatus(prev => ({ ...prev, valid: false }))
      }
      setFormStatus(prev => ({ ...prev, valid: true }))
    }
  }

  const handleChange = e => {
    const form = { ...formStatus }
    const name = e.target.name

    form.inputs[name] = e.target.value
    setFormStatus(form)
    checkValid()
  }

  const handleSubmit = () => {
    setFormStatus(prev => ({ ...prev, submitting: true }))
    const form = new FormData()
    for (let v in formStatus.inputs) {
      form.append(v, formStatus.inputs[v])
    }
    form.append("submit", "submit")

    fetch(`/util/mail.php`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: form,
    })
      .then(r => r.text())
      .then(res => {
        setFormStatus(prev => ({
          inputs: init,
          submitting: false,
          submitted: true,
        }))
      })
  }
  return (
    <ContactUsForm className={formStatus.submitting ? "submitting" : ""}>
      {formStatus.submitted ? (
        <p>Submitted! We will get back with you as soon as we can!</p>
      ) : (
        <>
          <label>Name</label>
          <input
            className="input"
            placeholder="Name"
            name="name"
            value={formStatus.inputs.name}
            onChange={handleChange}
          />
          <label>Email</label>
          <input
            className="input"
            name="email"
            placeholder="Email"
            value={formStatus.inputs.email}
            onChange={handleChange}
          />
          <label>Subject</label>
          <input
            className="input"
            name="subject"
            placeholder="Subject..."
            value={formStatus.inputs.subject}
            onChange={handleChange}
          />
          <label>Message</label>
          <textarea
            className="input"
            value={formStatus.inputs.message}
            name="message"
            placeholder="Your message..."
            onChange={handleChange}
          />
          <button onClick={() => handleSubmit()} className="btn">
            Submit
          </button>
        </>
      )}
    </ContactUsForm>
  )
}

ContactForm.propTypes = {}

export default ContactForm
