import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Hero from "../components/Hero"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import InnerWrap from "../components/_Wrap/inner-wrap"
import { makeLink } from "../utils/google-pic-format"

const SecondPage = ({ data }) => {
  const { Meta } = data
  return (
    <>
      <SEO
        title="Contact Brazee Studios"
        keywords={[`brazee`, `cincinnati`, `art classes`, `corporate awards`]}
      />
      <Hero src={makeLink(Meta.edges[0].node.headerPicture)}></Hero>

      <Container
        fluid
        style={{
          backgroundColor: "white",
          paddingTop: "4rem",
          paddingBottom: "4rem",
        }}
      >
        <Row>
          <InnerWrap>
            <Col md={12}>
              <h1
                className="blue"
                style={{ marginTop: "2rem", marginBottom: "4rem" }}
              >
                Contact Us
              </h1>
            </Col>
          </InnerWrap>
        </Row>
        <Row
          style={{
            maxWidth: "90%",
            margin: "auto",
          }}
        >
          <Col md={6}>
            <p
              dangerouslySetInnerHTML={{
                __html: Meta.edges[0].node.contactUsContent.replace(
                  /\n/g,
                  "<br />"
                ),
              }}
            />
          </Col>
          <Col md={6}>
            <iframe
              title="contact us iFrame"
              width="100%"
              height="100%"
              frameborder="0"
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJG6y4EHCtQYgRxaWOgG4GnnA&key=AIzaSyCfbfcmqrA2IGEPcKN0AdAkYK1UDtvvgKo"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
        <Row style={{ paddingTop: "6rem", maxWidth: "90%", margin: "auto" }}>
          <Col md={6}>
            <h6> Get In Touch! We'd love to hear from you!</h6>
            <ContactForm />
          </Col>
          <Col md={6}>
            <div style={{ width: "96%" }}>
              <img
                alt="Contact Us"
                style={{ width: "inherit", marginTop: "5rem" }}
                src={makeLink(Meta.edges[0].node.contactUsPicture)}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export const query = graphql`
  query ContactPageQuery {
    Meta: allGoogleSheetContactUsRow {
      edges {
        node {
          sitetitle
          headerPicture
          contactUsContent
          contactUsPicture
        }
      }
    }
  }
`

export default SecondPage
